export default {
  downloadBlob: (data, filename) => {
    const fileURL = window.URL.createObjectURL(new Blob([data]));
    const fileLink = document.createElement("a");
    fileLink.href = fileURL;
    fileLink.setAttribute("download", filename);
    document.body.appendChild(fileLink);

    fileLink.click();
  },
  downloadUrl: (url) => {
    const fileLink = document.createElement("a");
    fileLink.href = url;

    const filename = url.split("/").pop();
    fileLink.setAttribute("download", filename);
    document.body.appendChild(fileLink);

    fileLink.click();
  },
};
