<template>
  <div id="financial-plan">
    <section class="bg-blue1 text-center">
      <div class="container">
        <router-link to="/" class="mb-4 d-inline-block">
          <img src="/nedcollege/NEDCOLLEGE_logo_light.svg" alt="" height="75" />
        </router-link>

        <h2 v-html="lang.title" />
      </div>
    </section>
    <div class="float-section">
      <div class="container">
        <FinancialPlanForm />
      </div>
    </div>
    <section class="bg-green3">
      <div class="container">
        <h3 class="text-blue2">
          {{ lang.welcome.title }}
        </h3>

        <p>
          <b>
            {{ lang.welcome.subtitle }}
          </b>
        </p>

        <p>
          {{ lang.welcome.text }}
        </p>
      </div>
    </section>
    <div class="float-section">
      <div class="container">
        <img src="/landings/financial_plan/money.png" alt="" class="money" />
      </div>
    </div>
    <section class="bg-blue1">
      <div class="container d-flex flex-column gap-4">
        <button class="btn btn-blue2 btn-block" @click="toForm">{{ lang.cta }}</button>

        <h3 class="text-green3 text-center">{{ lang.why.title }}</h3>

        <div class="list-item" v-for="(item, i) of lang.why.items" :key="i">
          <img :src="item.icon" alt="" class="icon" />
          <p v-html="item.text" />
        </div>
      </div>
    </section>
    <div class="float-section">
      <div class="container">
        <img :src="lang.ebook_thumb" alt="" class="ebook-thumb" />
      </div>
    </div>
    <section class="bg-blue4">
      <div class="container text-center">
        <h4 class="mb-4" v-html="lang.cta_title" />
        <button class="btn btn-blue2 btn-block" @click="toForm">{{ lang.cta }}</button>
      </div>
    </section>
    <footer class="bg-blue2 text-center">
      <router-link to="/" class="mb-4 d-inline-block">
        <img src="/nedcollege/NEDCOLLEGE_logo_light.svg" alt="" height="75" />
      </router-link>

      <p class="m-0 text-green3">
        <b>{{ lang.footer.campus }}</b>
      </p>
      <p><small>© 2024 NED College</small></p>
    </footer>
  </div>
</template>

<script>
import FinancialPlanForm from "./FinancialPlanForm.vue";

export default {
  components: { FinancialPlanForm },
  computed: {
    lang() {
      return this.$lang();
    },
  },
  methods: {
    toForm() {
      const form = document.getElementById("form");
      form.scrollIntoView({ behavior: "smooth", block: "center" });
    },
  },
};
</script>
