<template>
  <div class="header_form bg-blue4" id="form">
    <img :src="lang.ebook_thumb" alt="" class="ebook-thumb" />
    <h4 class="text-blue2 mx-4">{{ lang.form.title }}</h4>

    <form class="form-row" @submit.prevent="onSubmit">
      <div class="col-12">
        <input type="text" :placeholder="lang.form.name" v-model="form.name" :class="{'input-error': formErrors.name}" />
      </div>
      <div class="col-6">
        <input type="text" :placeholder="lang.form.email" v-model="form.email" :class="{'input-error': formErrors.email}" />
      </div>
      <div class="col-6">
        <input type="text" :placeholder="lang.form.whatsapp" v-model="form.whatsapp" :class="{'input-error': formErrors.phone}"/>
      </div>
      <div class="col-12">
        <FormSelect
          v-model="form.nationality"
          :label="lang.form.nationality"
          :options="nationalities"
          :class="{'input-error': formErrors.q1}"
        />
      </div>
      <div class="col-12">
        <FormSelect v-model="form.campus" :label="lang.form.campus" :options="campuses" :class="{'input-error': formErrors.q2}" />
      </div>
      <div class="col-12">
        <FormSelect v-model="form.mkt_source" :label="lang.form.mkt_source" :options="mkt_source[this.$route.params.lang]" :class="{'input-error': formErrors.q3}" />
      </div>
      <div class="col-12">
        <button class="btn btn-blue-grad btn-block" @click.prevent="onSubmit">
          {{ lang.form.button }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import downloadFile from "@/utils/downloadFile.js";
import countries from "@/assets/json/countries-en.json";
import FormSelect from "@/components/Form/FormSelect";
import axios from "axios";

const { downloadUrl } = downloadFile;

export default {
  components: {
    FormSelect,
  },
  data: () => ({
    countries,
    tokens: {
      en: "ruy5hj11628fq1dl15g9317389wy1e4",
      es: "ruy5hj11628fq1dl15g9317389wy1e4",
      pt: "3vn6kc1162aup4vy15j8917389xbeyh",
      cn: "hkg7su11620hie8t15whe17389ey1ic",
      mn: "maopit1162yolvd915ogd17389eykdg",
    },
    campuses: [
      { value: "dublin", label: "Dublin" },
      { value: "limerick", label: "Limerick" },
    ],
    mkt_source: {
      es: [
        { value: "social-media", label: "Redes sociales" },
        { value: "email", label: "Correo electrónico" },
        { value: "google", label: "Google" },
        { value: "agent", label: "Agente de NED College" },
        { value: "webinar", label: "Webinar" },
        { value: "other", label: "Otro" },
      ],
      pt: [
        { value: "social-media", label: "Redes sociais" },
        { value: "email", label: "E-mail" },
        { value: "google", label: "Google" },
        { value: "agent", label: "Agente da NED College" },
        { value: "webinar", label: "Webinar" },
        { value: "other", label: "Outro" },
      ],
      cn: [
        { value: "social-media", label: "社交媒体" },
        { value: "email", label: "电子邮件" },
        { value: "google", label: "谷歌" },
        { value: "agent", label: "NED College代理" },
        { value: "webinar", label: "网络研讨会" },
        { value: "other", label: "其他" },
      ],
      mn: [
        { value: "social-media", label: "Нийгмийн сүлжээ" },
        { value: "email", label: "Имэйл" },
        { value: "google", label: "Гугл" },
        { value: "agent", label: "NED College агент" },
        { value: "webinar", label: "Вэбинар" },
        { value: "other", label: "Бусад" },
      ],
    },
    form: {
      name: null,
      email: null,
      whatsapp: null,
      nationality: null,
      campus: null,
      mkt_source: null,
    },
    formErrors: {
      name: false,
      email: false,
      phone: false,
      q1: false,
      q2: false,
      q3: false,
    },
  }),
  computed: {
    nationalities() {
      return countries.map((x) => ({
        value: x.name,
        label: x.name,
      }));
    },
    lang() {
      return this.$lang();
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: null,
        email: null,
        whatsapp: null,
        nationality: null,
        campus: null,
        mkt_source: null,
      };
    },
    onSubmit() {
      if (!this.validateForm()) return;

      const { lang } = this.$route.params;
      axios
        .get("https://api.pumpmylead.com/integrations/informs/v1/webhook", {
          params: {
            "your-name": this.form.name,
            "your-email": this.form.email,
            "your-phone": this.form.whatsapp,
            "your-nationality": this.form.nationality,
            "your-campus": this.form.campus,
            "your-mkt_source": this.form.mkt_source,
            token: this.tokens[lang],
          },
        })
        .then(() => {
          this.$router.push({ name: "financial_plan_thanks" });
          downloadUrl(`/landings/financial_plan/EBOOK-FINANCIAL-PLAN-${lang.toUpperCase()}.pdf`);
        });
    },

    validateForm() {
      let isValid = true;
      this.formErrors.name = false;
      this.formErrors.email = false;
      this.formErrors.phone = false;

      const regexName = /[^a-zA-Z\s]/;
      const emailRegex = /^\S+@\S+\.\S+$/;
      const phoneRegex = /^[\d+]+$/;


      if(!this.form.name || this.form.name.length < 3 || regexName.test(this.form.name)){
        isValid = false;
        this.formErrors.name = true;
      }
      if(!this.form.email || this.form.email.length < 3 || !emailRegex.test(this.form.email)){
        isValid = false;
        this.formErrors.email = true;
      }
      if(!this.form.whatsapp || this.form.whatsapp.length < 6 || !phoneRegex.test(this.form.whatsapp)){
        isValid = false;
        this.formErrors.phone = true;
      }
      if(!this.form.nationality){
        isValid = false;
        this.formErrors.q1 = true;
      }
      if(!this.form.campus){
        isValid = false;
        this.formErrors.q2 = true;
      }
      if(!this.form.mkt_source){
        isValid = false;
        this.formErrors.q3 = true;
      }
      return isValid;
    },
  },
};
</script>
<style>
.input-error::placeholder {
  color: red;
}
.input-error {
  color: red !important;
}
.input-error > select:invalid{
  color: red !important;
}
</style>
